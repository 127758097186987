import React from "react";
import Header from "components/Header";
import NounceTable from "../components/NounceTable";
import TotalSupplyTable from "../components/TotalSupplyTable";
import CollateralValue from "../components/CollateralValue";
import SynthBalanceTable from "../components/SynthBalanceTable";
// import { SystemHealth } from "../components/SystemHealth";

const Home = () => {
  return (
    <div className="main">
      <Header />

      <TotalSupplyTable />

      <CollateralValue />

      <SynthBalanceTable />

      <NounceTable />
      {/* <SystemHealth /> */}
    </div>
  );
};

export default Home;
