import { Connector } from '@web3-react/types'
import { injectedConnection } from './metaMask'

export function getConnections() {
  return [injectedConnection]
}

export function getConnection(c) {
  if (c instanceof Connector) {
    const connection = getConnections().find((connection) => connection.connector === c)
    if (!connection) {
      throw Error('unsupported connector')
    }
    return connection
  }

    return injectedConnection
}
