import React, { useState, useEffect, useMemo } from "react";
import { Web3ReactProvider } from '@web3-react/core'
import useOrderedConnections from "./hooks/useOrderedConnections";

const Providers = ({ children }) => {
  const connections = useOrderedConnections()
  const connectors = connections.map(({ hooks, connector }) => [connector, hooks])

  // Force a re-render when our connection state changes.
  const [index, setIndex] = useState(0)

  useEffect(() => setIndex((index) => index + 1), [connections])
  const key = useMemo(
    () => connections.map((connection) => connection.getName()).join('-') + index,
    [connections, index]
  )

  return (
    <Web3ReactProvider connectors={connectors} key={key}>{children}</Web3ReactProvider>
  );
};

export default Providers;
