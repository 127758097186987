import { BigNumber } from "ethers";

export const rpcs = {
  11155111: `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  43113: `https://avalanche-fuji.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  421614: `https://arbitrum-sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  80002: `https://polygon-amoy.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  599: 'https://goerli.gateway.metisdevops.link'
};

export const DEFAULT_NETWORK_ID = 421614;
export const BIG_ZERO = BigNumber.from(0);

export const AVAILABLE_NETWORKS = [11155111, 43113, 97, 421614, 80002];
export const AVAILABLE_NETWORKS2 = [11155111, 43113, 97, 421614, 80002];

export const networkNamesByChainId = {
  421614: "arb-sepolia",
  11155111: "sepolia",
  43113: "fuji",
  97: "bsc-testnet",
  80002: "amoy",
};

export const lzChainIds = {
  11155111: 40161,
  43113: 40106,
  421614: 40231,
  97: 40102,
  80002: 40267,
};

export const BASE_APP_URL = 'https://crosschaininfo.synthr.io/';

export const BRIDGE_GRAPH_API = {
  11155111: 'https://api.studio.thegraph.com/query/85468/test-sepolia-bridge/version/latest',
  43113: 'https://api.studio.thegraph.com/query/85468/test-fuji-bridge/version/latest',
  97: 'https://api.studio.thegraph.com/query/85468/test-bsctest-bridge/version/latest',
  421614: 'https://api.studio.thegraph.com/query/85468/test-arbsepolia-bridge/version/latest',
  80002: 'https://api.studio.thegraph.com/query/85468/test-amoy-bridge/version/latest',
}

export const GRAPH_API = {
  11155111: 'https://api.studio.thegraph.com/query/85468/test-sepolia-core/version/latest',
  43113: 'https://api.studio.thegraph.com/query/85468/test-fuji-core/version/latest',
  97: 'https://api.studio.thegraph.com/query/85468/test-bsctest-core/version/latest',
  421614: 'https://api.studio.thegraph.com/query/85468/test-arbsepolia-core/version/latest',
  80002: 'https://api.studio.thegraph.com/query/85468/test-amoy-core/version/latest',
}

export const connectorLocalStorageKey = "connectorIdSynthrDashboard";

export const mainChainId = 421614;