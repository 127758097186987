import React from 'react';
import Header from 'components/Header';
import PayloadTable from './components/PayloadTable';

const Payload = () => {

    return (
        <div className="main">
            <Header />
            <PayloadTable />
        </div>
    );
};

export default Payload;
