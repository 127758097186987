import { useMemo } from "react";
import useGetRpcs from "./useGetRpcs";
import { AVAILABLE_NETWORKS, mainChainId } from "config";
import {
  getExternStateTokenContract,
  getSynthrAggrContract,
  getSynthrBridgeContract,
  getTokenContract,
} from "utils/contractHelper";

export const useGetExternStateTokenContract = () => {
  const rpcs = useGetRpcs();
  const contracts = useMemo(() => {
    const temp = {};
    AVAILABLE_NETWORKS.forEach((chainId) => {
      temp[chainId] = getExternStateTokenContract(chainId, rpcs[chainId]);
    });
    return temp;
  }, [rpcs]);

  return contracts;
};

export const useGetBridgeContract = () => {
  const rpcs = useGetRpcs();
  const contracts = useMemo(() => {
    const temp = {};
    AVAILABLE_NETWORKS.forEach((chainId) => {
      temp[chainId] = getSynthrBridgeContract(chainId, rpcs[chainId]);
    });
    return temp;
  }, [rpcs]);

  return contracts;
};

export const useGetMainChainContracts = () => {
  const aggrContract = useMemo(() => {
    return getSynthrAggrContract(mainChainId);
  }, []);

  return {
    aggrContract,
  };
};

export const getTokenContractsBySymbol = async (rpcs, symbol) => {
  const temp = {};
  AVAILABLE_NETWORKS.forEach((chainId) => {
    temp[chainId] = getTokenContract(chainId, symbol, rpcs[chainId]);
  });
  return temp;
};
