import { useCallback } from "react";
import { getEndpointContract, getLzEndpointContract, getSynthrBridgeContract } from "utils/contractHelper";
import useActiveWeb3React from "./useActiveWeb3React";
import { ethers } from "ethers";
import { AxelarGMPRecoveryAPI, Environment, GMPStatus } from '@axelar-network/axelarjs-sdk';

const useRetryPayload = () => {
    const { library, chainId } = useActiveWeb3React();

    const handleRetryPayload = useCallback(
        async (item) => {
            console.log("Axelar item =>", item);
            if (item.axelar) {
                const sdk = new AxelarGMPRecoveryAPI({
                    environment: Environment.TESTNET,
                });
                const txStatus = await sdk.queryTransactionStatus(item.txn, item.data.call.logIndex);
                console.log("Booooooo Check the status =>",txStatus, item.data.id, txStatus.error.message, txStatus.error.message.includes('Insufficient gas'));
                // const response = await sdk.manualRelayToDestChain(
                //     item.txn, item.data.call.logIndex
                // );
                // console.log("manualRelayToDestChain =>", response );
                // return;
                if (txStatus.error.message.includes('Insufficient gas')) {
                    // Use this for source chain insufficient gass issues.
                    const res = await sdk.addNativeGas(
                        item.data.call.chain,
                        item.txn,
                        100000000
                    );
                    console.log("Axelar addNativeGas response",res );
                    return {
                        status : res.success,
                        error : res.error
                    }
                } else {
                    // execute manually
                    const respons = await sdk.execute(
                        item.txn, item.data.call.logIndex
                    );
                    console.log("Axelar execute response",respons );
                    return {
                        status : respons.success,
                        error : respons.error
                    }
                }
            } else {
                const payload = ethers.utils.arrayify(item.payload);
                try {
                    const origin = {
                        srcEid: item.srcChainId,
                        sender: item.srcAddress,
                        nonce: Number(item.nonce)
                      };
                    const contract = getLzEndpointContract(library?.getSigner());
                    const tx = await contract.lzReceive(origin,item.dstAddress,item.guid,payload,item.extraData);
                    const receipt = await tx.wait();
                    return receipt;
                } catch (e) {
                    console.log("Error in retry", e);
                }
            }
        },
        [library, chainId]
    );

    const handleRetryMessage = useCallback(
        async (item) => {
            const contract = getSynthrBridgeContract(chainId, library?.getSigner());
            const tx = await contract.retryMessage(item.srcChainId, item.srcAddress, item.nonce, item.payload);
            const receipt = await tx.wait();
            return receipt;
        },
        [library, chainId]
    );

    return {
        onRetryPayload: handleRetryPayload,
        onRetryMessage: handleRetryMessage
    }
}

export default useRetryPayload;
