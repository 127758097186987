import BigNumber from 'bignumber.js'

const BIG_TEN = new BigNumber(10);
export const BIG_ZERO = new BigNumber(0);

export function numberWithCommas(value) {
	var parts = value.split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	parts[1] = parts[1] ? parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
	return parts[1] ? parts.join('.') : parts[0];
}

export const getBalanceAmount = (amount, decimals = 18) => {
	return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));
}
  
export const getBalanceWithComma = (value, decimals = 18) => {
	const decimalValue = getBalanceAmount(value, decimals);
	return numberWithCommas(decimalValue.toString());
}

export const getDecimalAmount = (amount, decimals = 18) => {
	return new BigNumber(amount).multipliedBy(BIG_TEN.pow(decimals));
}

export const getShortenValue = (amount) => {
	const splitted = amount.split('.');
    return splitted[1] ? `${splitted[0]}.${splitted[1]?.substring(0, 2)}` : splitted[0];
}
