import { useEffect, useState } from "react";
import { getFailedMsgData, getStoredPayloadData, getFailedStoredPayloadData, getNotClearedFailedMsgData } from "utils/graphHelper";
import { AVAILABLE_NETWORKS } from "../config";
import { AxelarGMPRecoveryAPI, Environment, GMPStatus } from '@axelar-network/axelarjs-sdk';
import contracts from "../config/contracts";
import axios from "axios";

const useGetPayloadData = (cleard = false) => {
  const [loadedData, setLoadedData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const calls = AVAILABLE_NETWORKS.map((id) => {
        return cleard ? getFailedStoredPayloadData(id) : getStoredPayloadData(id);
      });
      const values = await Promise.all(calls).then((value) => {
        return value;
      });
      let tempData = {};
      AVAILABLE_NETWORKS.forEach((id, index) => {
        tempData[id] = values[index];
      });
      setLoadedData(tempData);
    };
    get();
  }, [cleard]);
  return loadedData;
};

export const useGetFailedMsgData = (cleared = false) => {
  const [loadedData, setLoadedData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const calls = AVAILABLE_NETWORKS.map((id) => {
        return cleared ? getNotClearedFailedMsgData(id) : getFailedMsgData(id);
      });
      const values = await Promise.all(calls).then((value) => {
        return value;
      });
      let tempData = {};
      AVAILABLE_NETWORKS.forEach((id, index) => {
        tempData[id] = values[index];
      });
      setLoadedData(tempData);
    };
    get();
  }, [cleared]);
  return loadedData;
};

export const useGetPayloadDataAx = (cleared = false) => {
  const [loadedData, setLoadedData] = useState(null);
  const sdk = new AxelarGMPRecoveryAPI({
    environment: Environment.TESTNET,
  });
  useEffect(() => {
    const get = async () => {
        try {
          const axelarBridge = contracts.SynthrBridgeAxelar;
          const __data = [];
          const _pro = Object.keys(axelarBridge).map(async id => {
            const res = await getBridgeFailedMessages(axelarBridge[id], 'error');
            if (res?.length > 0)
            __data.push(...res);
            return;
          })
          await Promise.all(_pro);
          const _pro1 = Object.keys(axelarBridge).map(async id => {
            const res = await getBridgeFailedMessages(axelarBridge[id], GMPStatus.INSUFFICIENT_FEE);
            if (res?.length > 0)
            __data.push(...res);
            return;
          })
          await Promise.all(_pro1);
          setLoadedData(__data);
        } catch (error) {
          console.error("Error:", error);
        }
    };
    get();
  }, [cleared]);
  return loadedData;
};

async function getBridgeFailedMessages (bridge, status) {
  const api = "https://testnet.api.axelarscan.io/gmp/searchGMP";
  const data = {
    address: bridge, // bridge contract address
    status: status,
  };
  const response = await axios.post(api, data);
  return response.data.data;
}

export default useGetPayloadData;
