import { DEFAULT_NETWORK_ID } from "config";
import contracts from "config/contracts";

export const getAddress = (address, chId) => {
  const chainId = Number(chId ?? DEFAULT_NETWORK_ID);
  return address[chainId];
};

export const getSynthrBridgeAddr = (chainId) => {
  return getAddress(contracts.synthrBridge, chainId);
};

export const getSynthrAggrAddr = (chainId) => {
  return getAddress(contracts.synthrAggregator, chainId);
};

export const getEndpointAddr = (chainId) => {
  return getAddress(contracts.endpoint, chainId);
};

export const getSyAssetAddr = (chainId, symbol) => {
  return getAddress(contracts[symbol], chainId);
};

export const getExternStateTokenAddr = (chainId) => {
  return getAddress(contracts.externWrappedStateToken, chainId);
};
