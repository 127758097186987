import { BIG_ZERO } from "..";

const syAssets = [
    {
        symbol: 'syUSD',
        currencyKey: '0x7355534400000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syETH',
        currencyKey: '0x7345544800000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syBTC',
        currencyKey: '0x7342544300000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syBNB',
        currencyKey: '0x73424e4200000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syAVAX',
        currencyKey: '0x7341564158000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syDOT',
        currencyKey: '0x73444f5400000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syMATIC',
        currencyKey: '0x734d617469630000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syAAPL',
        currencyKey: '0x734141504c000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syTSLA',
        currencyKey: '0x7354534c41000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syNVDA',
        currencyKey: '0x734e564441000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    },
    {
        symbol: 'syMSTR',
        currencyKey: '0x734d535452000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        aggrValue: BIG_ZERO
    }
];

export default syAssets;
