import { ethers } from "ethers";
import { getEndpointAddr, getExternStateTokenAddr, getSyAssetAddr, getSynthrAggrAddr, getSynthrBridgeAddr } from "./addressHelper";
import { getRpcProviderByChanId } from "./provider";
import endpointAbi from 'config/abis/Endpoint.json';
import bridgeAbi from 'config/abis/bridgeAbi.json';
import aggrAbi from 'config/abis/aggregatorAbi.json';
import erc20Abi from 'config/abis/erc20.json';
import externAbi from 'config/abis/externStateToken.json';
import systemSettingsAbi from 'config/abis/SystemSettingsAbi.json';
import layerZeroEndpointAbi from 'config/abis/layerZeroEndpointAbi.json';
import contracts from "config/contracts";
import { DEFAULT_NETWORK_ID } from "config";

const getContract = (
	abi,
	address,
	chainId,
	signer = undefined
) => {
	const rpcProvider = getRpcProviderByChanId(chainId);
	const signerOrProvider = signer ?? rpcProvider;
	return new ethers.Contract(address, abi, signerOrProvider);
};

export const getEndpointContract = (
	chainId,
	signer = undefined
) => {
	return getContract(endpointAbi, getEndpointAddr(chainId), chainId, signer);
};

export const getSynthrBridgeContract = (
	chainId,
	signer = undefined
) => {
	return getContract(bridgeAbi, getSynthrBridgeAddr(chainId), chainId, signer);
};

export const getSynthrAggrContract = (
	chainId,
	signer = undefined
) => {
	return getContract(aggrAbi, getSynthrAggrAddr(chainId), chainId, signer);
};

export const getExternStateTokenContract = (
	chainId,
	signer = undefined
) => {
	return getContract(externAbi, getExternStateTokenAddr(chainId), chainId, signer);
}

export const getTokenContract = (
	chainId,
	symbol,
	signer = undefined
) => {
	return getContract(erc20Abi, getSyAssetAddr(chainId, symbol), chainId, signer);
};

export const getSystemSettingsContract = (
	chainId,
	signer = undefined
) => {
	return getContract(systemSettingsAbi, contracts.systemSettings[chainId], chainId, signer);
};

export const getLzEndpointContract = (
	signer = undefined
) => {
	return getContract(layerZeroEndpointAbi, contracts.layerZeroEndpoint, DEFAULT_NETWORK_ID, signer);
}