import { ethers } from "ethers";
import sample from "lodash/sample";
import { DEFAULT_NETWORK_ID, rpcs } from "config";

export const getNodeUrl = (nodes) => {
  return sample(nodes);
};

export const getRpcProviderByChanId = (chId) => {
  const chainId = Number(chId ?? DEFAULT_NETWORK_ID);
  const RPC_URL = getNodeUrl([rpcs[chainId]]);
  return new ethers.providers.JsonRpcProvider(RPC_URL, "any");
};
