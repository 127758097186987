import { useMemo } from "react";
import { getRpcProviderByChanId } from "utils/provider";
import { AVAILABLE_NETWORKS } from "config";

const useGetRpcs = () => {
  const rpcs = useMemo(() => {
    const temp = {};
    AVAILABLE_NETWORKS.forEach((chainId) => {
      temp[chainId] = getRpcProviderByChanId(chainId);
    });
    return temp;
  }, []);

  return rpcs;
};

export default useGetRpcs;
