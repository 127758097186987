import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { networkNamesByChainId } from "config";
import { getNetworkIcon } from "utils";
import { setupNetwork } from "utils/wallet";
import { shortenSrcAddress, shortenTxHash } from "utils/formatString";
import CopyIcon from "assets/copy.svg";
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useRetryPayload from 'hooks/useRetryPayload';

const TableRow = ({ item }) => {
  const { account, chainId } = useActiveWeb3React();
  const [pending, setPending] = useState(false);

  const { onRetryPayload } = useRetryPayload();

  const handleTryPayload = async () => {
    if (!account) {
      toast.warning("Please connect wallet.");
      return;
    }
    if (chainId !== Number(item.chainId)) {
      await setupNetwork(Number(item.chainId));
      return;
    }
    setPending(true);
    try {
      const tx = await onRetryPayload(item);
      if (tx.status) {
        toast.success("Retried payload successfully!");
        setPending(false);
      } else {
        toast.error(tx?.error || "Failed to retry payload!");
        setPending(false);
      }
    } catch(err) {
      setPending(false);
      toast.error(err.message);
    }
  }

  return (
    <tr>
      <td className="chain-name">
        <img
          src={getNetworkIcon(networkNamesByChainId[item.chainId])}
          alt=""
          className="token-icon"
        />
        <div> {networkNamesByChainId[item.chainId]} </div>
      </td>
      <td className="values">
        <div className="d-flex">
          <CopyToClipboard text={item.txn}>
            <div className="svg-container">
              <img src={CopyIcon} alt="copy" />
            </div>
          </CopyToClipboard>
          <span> {shortenTxHash(item.txn)} </span>
        </div>
      </td>
      <td className="values">
        <span> {item.srcChainId} </span>
      </td>
      <td className="values">
        <div className="d-flex">
          <CopyToClipboard text={item.srcAddress}>
            <div className="svg-container">
              <img src={CopyIcon} alt="copy" />
            </div>
          </CopyToClipboard>
          <span> {shortenSrcAddress(item.srcAddress)} </span>
        </div>
      </td>
      <td className="values">
        <span>{item.nonce}</span>
      </td>
      <td
        className={item.cleared ? "values success-value" : "values wrong-value"}
      >
        <span> {item.cleared ? "true" : "false"} </span>
      </td>
      <td className="values" >
        <button className="retry-button" onClick={handleTryPayload} disabled={item.cleared}> {pending ? "Processing..." : "Retry Payload"} </button>
      </td>
    </tr>
  );
};

export default TableRow;
