import { useState, useEffect } from "react";
import syAssets from "config/constants/syAssets";
import { AVAILABLE_NETWORKS, BIG_ZERO } from "config";
import useGetRpcs from "./useGetRpcs";
import { getTokenContractsBySymbol, useGetMainChainContracts } from "./useContracts";
import allContracts from "config/contracts";

const useGetTotalSupply = () => {
  const [data, setData] = useState(null);

  const { aggrContract } = useGetMainChainContracts();
  const rpcs = useGetRpcs();

  useEffect(() => {
    const get = () => {
      const temp = [];
      syAssets.forEach(async (_) => {
        try {

          const contracts = await getTokenContractsBySymbol(rpcs, _.symbol);
          const calls = AVAILABLE_NETWORKS.map((chainId) => {
            return contracts[chainId]?.totalSupply();
          });
          const values = await Promise.all(calls).then((val) => {
            return val;
          });
          const sum = values.reduce((a, b) => a.add(b), BIG_ZERO);
  
          const totalSupply = {};
          AVAILABLE_NETWORKS.forEach((chainId, index) => {
            totalSupply[chainId] = values[index];
          });
  
          let sumRedeemer = 0;
          if (_.symbol === 'syUSD') {
            const redeemercalls = AVAILABLE_NETWORKS.map((chainId) => {
              return contracts[chainId]?.balanceOf(allContracts['synthrRedeemer'][chainId]);
            });
            const redeemerValues = await Promise.all(redeemercalls).then((val) => {
              return val;
            });
            sumRedeemer = redeemerValues.reduce((a, b) => a.add(b), BIG_ZERO);
          }
          let aggrValue = 0;
          try {
            aggrValue = await aggrContract.synthTotalSupply(_.currencyKey);
            aggrValue = aggrValue.add(sumRedeemer);
          } catch (el) {
            console.log("Error in synthTotalSupply =>", el);
          }
  
          temp.push({
            ..._,
            totalSupply,
            sum,
            aggrValue,
          });
          if (temp.length === syAssets.length) {
            setData(temp);
          }
        }catch(err) {
          console.log("Error in the total supply =>", err);
        }
      });
    };
    if (rpcs && !!aggrContract) {
      get();
    }
  }, [rpcs, aggrContract]);

  return data;
};

export default useGetTotalSupply;
