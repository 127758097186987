import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SynthrLogo } from "../../assets/synthr-logo.svg";
import ConnectWalletButton from '../ConnectWalletButton';

const Header = () => {
  const arrParams = window.location.href.split('/');
	const lastItem = `/${arrParams[arrParams.length - 1]}`;

  return(
    <div className='d-flex header justify-content-between'>
      <SynthrLogo className='synthr-logo' />
      <div className='d-flex'>
        <Link className={`header-link ${lastItem === '/' ? 'active-link' : ''}`} to={'/'}>Home</Link>
        <Link className={`header-link ${lastItem === '/payloads' ? 'active-link' : ''}`} to={'/payloads'}>Payloads</Link>
      </div>
      <ConnectWalletButton />
    </div>
  )
}

export default Header;
