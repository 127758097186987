const contracts = {
    "synthrBridge": {
        1: '',
        // 5: '0x11f66d6864FcCe84D5000E1E981DD58676633949',
        // 43113: '0xb92DE63eb7D8A652bF80385906812F92D49C5139',
        43113: '0x9038f55ca0e2aF8c4413bFe2855B912116ee0bE7',
        56: '',
        97: '0x1C81e23e1c185d3bEDE09F35e598ba8fdf7BbEd4',
        // 421613: '0x4C11cCeE50B70dAa47c41849e45316d975b26102',
        // 421613: '0xDf202840bA1509c093434140F4111ceBE8D8b506',
        421614: '0xb0Fc7c9ca93886FAc2F29e67e74115e8B66d1FA6',
        // 80001: '0x97Fd3D4508704B3a55C48639E6A8db4090D02a24',
        80002: '0xcD23dBD7Bdd30b5a9B31D4D7064C78fF0CE4C55d',
        // 599: '0x484f8F52DDb47B5F6633d1ca354c41A426D643B9',
        11155111: '0xb0608C86A850e1C33c965272dF19ab686061Af28'
    },
    "SynthrBridgeAxelar": {
        43113: '0xf171423cdf6865e1E451F403EB9E43B6e628C8d0',
        97: '0x0C7e8ED82641932879c31171955C42dbB1e25f2a',
        421614: '0x0cCa3ebF1a0c462dFd2e041252F97D85F83D234B',
        80002: '0xA5dd4d173E8fC33E2940379Bd2c93fb8E4785A0a',
        11155111: '0xFAC3D81644976e6dea44Ad9D7c9B8558A6cea33d'
    },
    "synthrAggregator": {
        1: '',
        // 5: '',
        43113: '',
        56: '',
        97: '',
        // 421613: '0xFfb3A525247A5bEC132339D6977e0B222A20dD5A',
        // 421613: '0x02dC40F7a2cBcDEcE46053dB7cf73C66a34A7073',
        421614: '0x06c36CeF31830c0D587Da0c1DA0F1CA8c49ecaC3',
        80002: '',
        // 599: '',
        11155111: ''
    },
    "externWrappedStateToken": {
        1: '',
        // 5: '0x93A90d04484b6a1D1aA6C4Ab21FBbFfEBa59eaFD',
        43113: '0x052cd69e706c87939f968b558F719792151bB8a0',
        56: '',
        97: '0xC5E3e7a620FFe63524cdCA469732DC36080eA33B',
        // 421613: '0xA6418caF5e0C63F335920E3Fef1893eDb448D212',
        // 421613: '0xA6418caF5e0C63F335920E3Fef1893eDb448D212',
        421614: '0x9DBd3D752B77ED59c5ae699E08D0e53fFf9c7fa2',
        80002: '0x265B47eA6DfFCb8f4567099b7414fB0b473979d6',
        // 599: '0x9Fd279E28D5f9D0DB2d62E92905D85B2e3f08f82',
        11155111: '0xB0d394977A13bBDE97A939519BBa47A32F6b3342'
    },
    "endpoint": {
        1: '0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675',
        // 5: '0xbfD2135BFfbb0B5378b56643c2Df8a87552Bfa23',
        43113: '0x6edce65403992e310a62460808c4b910d972f10f',
        56: '0x3c2269811836af69497E5F486A85D7316753cf62',
        97: '0x6edce65403992e310a62460808c4b910d972f10f',
        // 421613: '0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab',
        421614: '0x6edce65403992e310a62460808c4b910d972f10f',
        80002: '0x6edce65403992e310a62460808c4b910d972f10f',
        1088: '0x9740FF91F1985D8d2B71494aE1A2f723bb3Ed9E4',
        // 599: '0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1',
        11155111: '0x6edce65403992e310a62460808c4b910d972f10f'
    },
    "syUSD": {
        1: '',
        5: '0x8Bd0E58032E5343C888EBA4e72332176fffa7371',
        43113: '0x3d5d389c57951976d119e60f7993613d35282b67',
        56: '',
        97: '0x606bC92f8a4539144c6859a01B6AAFA604ce25F3',
        //421613: '0x20fA210691a62B47baeDf0412cF8BB231BDA59F3',
        421614: '0xf6Cc2e9c302D28474233Fd830DD68Baed9D6352f',
        80002: '0xf414627F50549F4d691ADd918E7262400F9137D6',
        //599: '0xEFeEdB6f4DecAD460170c036379dF0d074DB7fc2',
        11155111: '0xf96f939300683302A1a8319F1d84192dC47c8602'
    },

    "syETH": {
        1: '',
        5: '0xD6BbA8f8443083Af9218AA88De9931157A391eB5',
        43113: '0x1C34dca676c7C351779672f1a7B6eC9FBA14E25e',
        56: '',
        97: '0x3B0E569D4601F6b2777c87f57cf4a53c921F9c53',
        //421613: '0x104893ccE94d4661a089c7Aab964779255684825',
        421614: "0x39Fc4D87a5cf98A2b2586617B73AA441C3455848",
        80002: '0x948fD269f9e96F2A2356Bb034cdae2C077400b02',
        599: '0xe1639984C963EE2141d08A496e745Da58A1EF9A1',
        11155111: '0x5CF30933d00D1Aff70bb8A3848dBb5d9ed6ae2bb'
    },
    "syBTC": {
        1: '',
        5: '0x4930E28CC58A2Caa9533dA4cC3FB2471876D7481',
        43113: '0x539B762781D6b2504Bd9B8F711449db7f6e31F00',
        56: '',
        97: '0x0A481259647aCDEECbb8C242DA6b18F75745e98B',
        421613: '0xa61a293b8eA692758bF963629F18620929F55f22',
        421614: '0xfdDFd9879Ee0d80A559E1156ae87CD9376a3D1a0',
        80002: '0x805849d5Fe4Eb782e768D7c356a758571977585C',
        599: '0x034b7Ccf069Fe523dE04f765Fc22356A099a9853',
        11155111: '0xFfb2C0031aC2574eF952A51C3deCd9B6f036281E'
    },
    "syBNB": {
        1: '',
        5: '0xf608D873f7DAC5DFB03ece090beBcb5d7851A0c6',
        43113: '0x48dA709018d0Ac1A83ccbC41e4da3B3A54393065',
        421614: '0x2B3324A9605d6e3999eeEFCa7174fE96ac4fDF47',
        56: '',
        97: '0x5CF30933d00D1Aff70bb8A3848dBb5d9ed6ae2bb',
        421613: '0x0836080e92323436e54F64A56ADE96433A081582',
        80002: '0xbb66d8aa7D663B5fF02Bf71214573220aC877a7D',
        599: '0x4752a203e8867f5c2B760dA9689CafbE01d4d8B1',
        11155111: '0x0E7203Bc83245bfDCcB34062ef98D4Ab6A26dd4a'
    },
    "syAVAX": {
        1: '',
        5: '0x9930393889fDC1e2AdE39AFCe30Dfe688826196f',
        43113: '0x4b8Bf55f5EF1196696845F73C16842FEA7a6D1e5',
        421614: '0x23cc9d9D085B0A6ea0a5173c0e1C5589913F62e8',
        56: '',
        97: '0x098A2f93A7e29a312cabA4edB1d98321EEF38cf0',
        421613: '0xBdE72eCca93fB84f0FB9Ec0c79cF66e2f29fbD8a',
        80002: '0xE8F79D609c7736E0B1062032BEd6ABa1B76FB603',
        599: '0xeB4467Bb34E3f4cD2F2052452BDFb55600B1CD60',
        11155111: '0xBd2df2492f3839AFCc9aDc81BE92c1B763D0A983'
    },
    "syDOT": {
        1: '',
        5: '0xAfAC8Df44A724Ea386b08e0AE7C8fE8a4723DCB8',
        43113: '0xc546C2E4f20b7A386ae8bDd0e2a08eFF9386aeCa',
        56: '',
        97: '0xBcf36236b1777cC8Fd4A7e3ee10c74b1e9D5F0A3',
        421613: '0x4902a5914f124Bdf79163c7dCE4313Ac9E944808',
        421614: '0x7A540f8D80c8c0ecbd7F00Fa5DfA56Ad3F15e812',
        80002: '0xC9DEb173cE3E566469796e64271F03458459aB7C',
        599: '0x786505828c29E7ca0C292C32c5Be01FCA77D8C54',
        11155111: '0x098A2f93A7e29a312cabA4edB1d98321EEF38cf0'
    },
    "syMATIC": {
        1: '',
        5: '0x3e49fC69dC6b0e9a21d8e067732f60d97d3a8D53',
        43113: '0xD8A468342F65E2d16991EC5681856BBC8b8B9212',
        56: '',
        97: '0xf96f939300683302A1a8319F1d84192dC47c8602',
        421613: '0xA11084cC03B53bcD8aB87f6f28F2aeDd45F0C0c6',
        421614: '0x249Ae61f3b3d6Bcd4B753d7a7287FC250aCE8A16',
        80002: '0xc634519289504DcB745Eb4C87d3276B87De3054b',
        599: '0xEb83Ea3e5E6F33BA693B6a02a3b8B55205403F77',
        11155111: '0xBcf36236b1777cC8Fd4A7e3ee10c74b1e9D5F0A3'
    },
    "syAAPL": {
        43113: '0xBC594b116B8e63cE8bE065BBb29652dc365b8e55',
        97: '0xFfb2C0031aC2574eF952A51C3deCd9B6f036281E',
        421614: '0x98FEd5a570a4842306F578A615A17472b17939bf',
        80002: '0x0D6F4FF6B8d20a7E8f353101be22A601023CE79e',
        11155111: '0xb621846f4302525bD7E640031BD39084fe669536'
    },
    "syTSLA": {
        43113: '0xc1495656E4Db24A429090bd70fb8512a1CffaD48', // fuji
        97: '0x043dFC30142C0d7Ea5F7050295d000c1A9c72614', // bsctest
        421614: '0x1604028Cb456b90e3b247E23A9d9dEfAf67166E6', // arb-sepolia
        80002: '0xD627Eec068542C87c9Ff9864a41dBDb12578F216', // amoy
        11155111: '0x11cf84038F21BC38bEA9ab74380847694Ae9e061' // sepolia
    },
    "syNVDA": {
        43113: '0x3042d9579fA2B9C13198ee2569C62e43C1e7bC74',
        97: '0xb0608C86A850e1C33c965272dF19ab686061Af28',
        421614: '0xdE8Cd9C6de66C3fbCdbdF961366Bc016F543677A',
        80002: '0x847170a1EC0cAfE31CFcE6AbB5985E42B062a9C0',
        11155111: '0x3B0E569D4601F6b2777c87f57cf4a53c921F9c53'
    },
    "syMSTR": {
        43113: '0x750BAfA099FC8E764D5B9879C7b4DFC74893DC94',
        97: '0x11cf84038F21BC38bEA9ab74380847694Ae9e061',
        421614: '0x892CCB795fa11014C8175d48293a19F597DE1605',
        80002: '0x53F7900945a213d73D17B9858E21dAdB1b19e36c',
        11155111: '0x0A481259647aCDEECbb8C242DA6b18F75745e98B'
    },
    "synthrRedeemer": {
        43113: '0x87dA88De6BB0486FD6B41F6E253CAE404cD83297',
        97: '0x21854040f0658a7743D7243888328280d61BbCED',
        421614: '0x0313894Fe86b37164f93dD59d7a9974107c97c9B',
        80002: '0x92678Fa97eC0FDf73687C4Fb8cDf7Dc18CDe3f58',
        11155111: '0x2921b3bd85815d970E6D51320eAeC8d8286f6844'
    },
    "systemSettings": {
        43113: '0x1D6D2D5992e016c0e6362f38E8f1449deBba442c',
        97: '0x1D6D2D5992e016c0e6362f38E8f1449deBba442c',
        421614: '0xb6097D3Ec70B529046deF73dCaBCa6c01BB7D795',
        80002: '0xe0875CBD144Fe66C015a95E5B2d2C15c3b612179',
        11155111: '0x1D6D2D5992e016c0e6362f38E8f1449deBba442c'
    },
    "layerZeroEndpoint": '0x6edce65403992e310a62460808c4b910d972f10f'
}

export default contracts;