import React, { useState } from "react";
import useGetSynthBalance from "hooks/useGetSynthBalance";
import BalanceTbRow from "./BalanceTbRow";
import NonceTableRowForUser from "./NonceTableRowForUser";
import useGetNoncesByUser from "hooks/useGetNoncesByUser";
import { AVAILABLE_NETWORKS, networkNamesByChainId } from "config";

const SynthBalanceTable = () => {
  const [address, setAddress] = useState("");
  const data = useGetSynthBalance(address);
  const nonceData = useGetNoncesByUser(address);

  const handleChange = (e) => {
    const value = e.target.value;
    if (!value.includes("0x") && value.length > 2) {
      setAddress("");
      return;
    }
    setAddress(value);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="aggregator-title" id="userStatus">User Status</div>
        <div className="d-flex">
          <div className="values">Wallet Address :</div>
          <input
            className="wallet-input"
            value={address}
            placeholder="Input a wallet address..."
            onChange={handleChange}
            id="wallet-address-input"
          />
        </div>
      </div>

      {data && nonceData && address !== "" ? (
        <>
          <div className="aggregator-value-wrapper">
            <div className="aggregator-sub-title">Collateral Values</div>
            <div className="aggregator-value-container">
              <table className="aggregator-table">
                <thead>
                  <tr>
                    <th style={{ width: 110 }}>syAsset</th>
                    {AVAILABLE_NETWORKS.map((chId) => {
                      return (
                        <th key={networkNamesByChainId[chId]}>
                          <div className="d-clomumn">
                            <span>{`${networkNamesByChainId[chId]}`}</span>
                            <span>(Aggr/State)</span>
                          </div>
                        </th>
                      );
                    })}
                    <th>Sum(Aggr/State)</th>
                    <th>In Aggregator</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return <BalanceTbRow item={item} key={index} />;
                  })}
                </tbody>
              </table>
            </div>

            <div className="aggregator-sub-title">Nonce Values</div>
            <div className="aggregator-value-container">
              <table className="aggregator-table">
                <thead>
                  <tr>
                    <th>Pairs(from/to Network)</th>
                    <th>OutBound Nonce</th>
                    <th>InBound Nonce</th>
                    <th>Status</th>
                    <th>OutBound Txn</th>
                  </tr>
                </thead>
                <tbody>
                  {nonceData.map((item, index) => {
                    return <NonceTableRowForUser item={item} key={index} />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : address !== "" ? (
        <div className="d-flex empty-card text">Loading...</div>
      ) : (
        <div className="d-flex empty-card text">
          Please input a address which you want to check.
        </div>
      )}
    </div>
  );
};

export default SynthBalanceTable;
