import { getConnection } from 'connectors'
import { ConnectionType } from 'connectors/types'
import { useMemo } from 'react'

const SELECTABLE_WALLETS = [
  ConnectionType.INJECTED,
]

export default function useOrderedConnections() {

  return useMemo(() => {
    return SELECTABLE_WALLETS.map((connectionType) => getConnection(connectionType))
  }, [])
}
