import { DEFAULT_NETWORK_ID } from 'config';

console.log('>>DEFAULT_NETWORK_ID', {DEFAULT_NETWORK_ID})
export const setupNetwork = async (chainId = DEFAULT_NETWORK_ID) => {
  const provider = window.ethereum;
  if (provider) {
    // const chainId = process.env.REACT_APP_CHAIN_ID
    //   ? parseInt(process.env.REACT_APP_CHAIN_ID, 10)
    //   : 1;
    // const chainId = _chainId
    //   ? parseInt(_chainId, 10)
    //   : parseInt(process.env.REACT_APP_CHAIN_ID, 10);
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
          },
        ],
      });
      return true;
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error);
      return false;
    }
  } else {
    console.error("Can't setup network on metamask because window.ethereum is undefined");
    return false;
  }
};
