import { useEffect, useState, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getRpcProviderByChanId } from 'utils/provider';
import { DEFAULT_NETWORK_ID } from 'config';

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const { provider, chainId, ...web3React } = useWeb3React();
  const refEth = useRef(provider);
  const [provider1, setprovider] = useState(provider);

  useEffect(() => {
    if (provider !== refEth.current) {
      const simpleRpcProvider = getRpcProviderByChanId(chainId);
      setprovider(provider || simpleRpcProvider);
      refEth.current = provider;
    }
  }, [provider, chainId]);

  return {
    library: provider1,
    chainId: chainId ?? DEFAULT_NETWORK_ID,
    ...web3React,
  };
};

export default useActiveWeb3React;
