import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Payload from "./pages/Payload";

const RoutesConfig = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/payloads" element={<Payload />} />
    </Routes>
  </Router>
);
export default RoutesConfig;
