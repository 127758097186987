import { request, gql } from "graphql-request";
import { BRIDGE_GRAPH_API, lzChainIds, GRAPH_API } from "config";

export const getStoredPayloadData = async (chainId) => {
  const endpoint = BRIDGE_GRAPH_API[chainId];
  const response = await request(
    endpoint,
    gql`
      query getStoredPayloadData {
        storedPayloads(first: 1000) {
          id
          blockNumber
          timestamp
          srcChainId
          srcAddress
          dstAddress
          nonce
          txn
          clearedTxn
          guid
          extraData
          payload
          reason
          cleared
        }
      }
    `
  );
  return response.storedPayloads;
};

export const getFailedStoredPayloadData = async (chainId) => {
  const endpoint = BRIDGE_GRAPH_API[chainId];
  const response = await request(
    endpoint,
    gql`
      query getStoredPayloadData {
        storedPayloads(first: 1000, where: {cleared: false}) {
          id
          blockNumber
          timestamp
          srcChainId
          srcAddress
          dstAddress
          nonce
          txn
          guid
          extraData
          clearedTxn
          payload
          reason
          cleared
        }
      }
    `
  );
  return response.storedPayloads;
};

export const getFailedMsgData = async (chainId) => {
  const endpoint = BRIDGE_GRAPH_API[chainId];
  const response = await request(
    endpoint,
    gql`
      query getFailedMsgData {
        lzReceiveAlerts(
          first: 1000
          orderBy: id
          where: {receiver: "``"}) {
            id
            blockNumber
            reason
            blockTimestamp
            transactionHash
            extraData
            origin_srcEid
            origin_nonce
            value
            origin_sender
        }
      }
    `
  );
  return response.failedMessages;
};

export const getNotClearedFailedMsgData = async (chainId) => {
  const endpoint = BRIDGE_GRAPH_API[chainId];
  const response = await request(
    endpoint,
    gql`
      query getFailedMsgData {
        failedMessages(first: 1000, where: {cleared: false}) {
          id
          blockNumber
          timestamp
          txn
          cleared
          clearedTxn
          nonce
          payload
          reason
          srcAddress
          srcChainId
        }
      }
    `
  );
  return response.failedMessages;
};

export const getUserNonceFromBridge = async (
	fromChainId,
	toChainId,
	account,
  path = ''
) => {
	const lzId = lzChainIds[toChainId];
	const response = await request(
		BRIDGE_GRAPH_API[fromChainId],
		gql`
      query getNonceFromBridge($account: String!, $path: String!) {
        accountPackets(where: {targetChainId: ${lzId}, account: $account, path: $path}) {
          id
          path
          inBoundNonce
          lastInboundTxn
          lastOutBoundTxn
          outBoundNonce
          targetChainId
        }
      }
      `,
      { account, path }
	);
	return response.accountPackets;
};

export const getBoundPacket = async (
	fromChainId,
	toChainId
) => {
	const lzId = lzChainIds[toChainId];
	const response = await request(
		BRIDGE_GRAPH_API[fromChainId],
		gql`
      query {
        boundPacketSnapShots(where: {targetChainId: ${lzId}}) {
          id
          lastInBoundNonce
          lastOutBoundNonce
          targetChainId
          path
        }
      }`);
	return response.boundPacketSnapShots;
};

export const getInBoundNonce = async (
  fromChainId,
	toChainId
) => {
	const lzId = lzChainIds[fromChainId];
	const response = await request(
		BRIDGE_GRAPH_API[toChainId],
		gql`
      query getInBoundNonce {
        inBoundPackets(
          where: {srcChainId: ${lzId}}
          orderBy: nonce
          orderDirection: desc
          first: 1
        ) {
          nonce
          id
        }
      }
      `
	);
	return response.inBoundPackets;
};

export const getOutBoundNonce = async (
  fromChainId,
	toChainId
) => {
	const lzId = lzChainIds[toChainId];
	const response = await request(
		BRIDGE_GRAPH_API[fromChainId],
		gql`
      query getOutBoundNonce {
        outBoundPackets(
          where: {dstChainId: ${lzId}}
          orderBy: nonce
          orderDirection: desc
          first: 1
        ) {
          nonce
          id
        }
      }
      `
	);
	return response.outBoundPackets;
};

export const getCollateralValue = async (
	chanId,
  symbol
) => {
	const response = await request(
		GRAPH_API[chanId],
		gql`
      query getCollateralValue($symbol: String!) {
        collateralPositions(where: {collateralSymbol: $symbol}) {
          id
          tvl
          collateralSymbol
        }
      }
      `,
      {symbol}
	);
	return response.collateralPositions;
};

export const getCollateralTotalValue = async (
	chanId,
  symbol
) => {
	const response = await request(
		GRAPH_API[chanId],
		gql`
      query getCollateralTotalValue($symbol: String!) {
        crossChainCollateralPositions(where: {collateralSymbol: $symbol}) {
          tvl
          collateralSymbol
        }
      }
      `,
      {symbol}
	);
	return response.crossChainCollateralPositions;
};
