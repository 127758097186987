import Routes from "./RoutesConfig";
import { ToastContainer } from "react-toastify";
import BigNumber from "bignumber.js";
import "./index.css";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
// import useEagerConnect from "./hooks/useEagerConnect";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

function App() {
  // useEagerConnect()
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </div>
  );
}

export default App;
