import React from "react";
import useGetNonces from "hooks/useGetNonces";
// import useGetTotalSupplyInAggr from "../../hooks/useGetTotalSupplyInAggr";
import NonceTableRow from "./NonceTableRow";

const NounceTable = () => {
  // const totalSupplyData = useGetTotalSupplyInAggr();
  const data = useGetNonces();

  // const msgStatus = useMemo(() => {
  //   let res = true;
  //   totalSupplyData?.forEach(element => {
  //     if (!element.status) {
  //       res = false;
  //     }
  //   });
  //   return res;
  // }, [totalSupplyData]);

  return (
    <div>
      <div className="aggregator-title" style={{marginTop : "50px"}}>Bound Nonces</div>

      {data ? (
        <div className="aggregator-value-wrapper">
          <div className="aggregator-value-container">
            <table className="aggregator-table">
              <thead>
                <tr>
                  <th>Pairs</th>
                  <th>Out Bound Nonce</th>
                  <th>In Bound Nonce</th>
                  <th>Status</th>
                  <th>Path</th>
                  {/* <th>Message Status</th> */}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <NonceTableRow item={item} key={index} />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex empty-card text">Loading...</div>
      )}
    </div>
  );
};

export default NounceTable;
