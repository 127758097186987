import { BIG_ZERO } from "..";

export const noncePairs = [
    {
        id: 'noncePair-1',
        fromChainId: 11155111,
        toChainId: 43113,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-2',
        fromChainId: 11155111,
        toChainId: 421614,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-3',
        fromChainId: 11155111,
        toChainId: 97,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-4',
        fromChainId: 43113,
        toChainId: 11155111,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-5',
        fromChainId: 43113,
        toChainId: 421614,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-6',
        fromChainId: 43113,
        toChainId: 97,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-7',
        fromChainId: 97,
        toChainId: 11155111,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-8',
        fromChainId: 97,
        toChainId: 43113,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-9',
        fromChainId: 97,
        toChainId: 421614,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-10',
        fromChainId: 421614,
        toChainId: 11155111,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-11',
        fromChainId: 421614,
        toChainId: 43113,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-12',
        fromChainId: 421614,
        toChainId: 97,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-13',
        fromChainId: 421614,
        toChainId: 80002,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-14',
        fromChainId: 11155111,
        toChainId: 80002,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-15',
        fromChainId: 97,
        toChainId: 80002,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-16',
        fromChainId: 43113,
        toChainId: 80002,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-17',
        fromChainId: 80002,
        toChainId: 97,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-18',
        fromChainId: 80002,
        toChainId: 43113,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-19',
        fromChainId: 80002,
        toChainId: 11155111,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    {
        id: 'noncePair-20',
        fromChainId: 80002,
        toChainId: 421614,
        outNonce: BIG_ZERO,
        inNonce: BIG_ZERO,
    },
    // {
    //     id: 'noncePair-21',
    //     fromChainId: 421614,
    //     toChainId: 599,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-22',
    //     fromChainId: 11155111,
    //     toChainId: 599,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-23',
    //     fromChainId: 97,
    //     toChainId: 599,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-24',
    //     fromChainId: 43113,
    //     toChainId: 599,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-25',
    //     fromChainId: 599,
    //     toChainId: 97,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-26',
    //     fromChainId: 599,
    //     toChainId: 43113,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-27',
    //     fromChainId: 599,
    //     toChainId: 11155111,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-28',
    //     fromChainId: 599,
    //     toChainId: 421614,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-29',
    //     fromChainId: 80001,
    //     toChainId: 599,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
    // {
    //     id: 'noncePair-30',
    //     fromChainId: 599,
    //     toChainId: 80001,
    //     outNonce: BIG_ZERO,
    //     inNonce: BIG_ZERO,
    // },
];
