import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { connectorLocalStorageKey } from '../config';
import { getConnection } from 'connectors';
import { didUserReject } from 'utils/errors';
import { ConnectionType } from 'connectors/types';

const useAuth = () => {
  const { connector } = useWeb3React()

  const login = useCallback(
    async (connectorID = ConnectionType.INJECTED) => {
      const connection = getConnection(connectorID)
      window.localStorage.setItem(connectorLocalStorageKey, connectorID)
      try {
        if (connection) {
          await connection.connector.activate()
        } else {
          console.error('Unable to find connector', 'The connector config is wrong')
          // toastError('Unable to find connector', 'The connector config is wrong')
        }
      } catch (error) {
        if (didUserReject(connection, error)) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          return
        }
      }
    },
    [],
  )

  const logout = useCallback(async () => {
    if (connector && connector.deactivate) {
      await connector.deactivate()
    }
    connector.resetState()
    window.localStorage.removeItem(connectorLocalStorageKey)
  }, [connector])

  return { login, logout };
};

export default useAuth;
