import React from "react";
import useGetTotalSupply from "hooks/useGetTotalSupply";
import TtSupplyTbRow from "./TtSupplyTbRow";
import { AVAILABLE_NETWORKS, networkNamesByChainId } from "config";

const TotalSupplyTable = () => {
  const data = useGetTotalSupply();
  return (
    <div>
      <div className="aggregator-title">Synth Total Supply</div>

      {data ? (
        <div className="aggregator-value-wrapper">
          <div className="aggregator-value-container">
            <table className="aggregator-table">
              <thead>
                <tr>
                  <th style={{ width: 110 }}>syAsset</th>
                  {AVAILABLE_NETWORKS.map((chId) => {
                    return (
                      <th
                        key={networkNamesByChainId[chId]}
                      >{`${networkNamesByChainId[chId]}`}</th>
                    );
                  })}
                  <th>Sum</th>
                  <th>In Aggregator</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return <TtSupplyTbRow item={item} key={index} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex empty-card text">Loading...</div>
      )}
    </div>
  );
};

export default TotalSupplyTable;
