import { Tooltip } from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getImageUrl } from "utils";
import { getBalanceWithComma, getShortenValue } from "utils/number";
import { AVAILABLE_NETWORKS } from "config";

const BalanceTbRow = ({ item }) => {
  return (
    <tr>
      <td className="total-supply-name mt-1">
        <img src={getImageUrl(item.symbol)} alt="" className="token-icon" />
        <div> {item.symbol} </div>
      </td>
      {AVAILABLE_NETWORKS.map((chainId) => {
        const converted1 = getBalanceWithComma(
          item.balance[chainId].toString()
        );
        const converted2 = getBalanceWithComma(
          item.balanceInAggr[chainId].toString()
        );
        return (
          <td className="values" key={`key-${chainId}`}>
            <div className="d-clomumn">
              <CopyToClipboard text={converted1}>
                <span data-tooltip-id={`${item.symbol}-${chainId}`}>
                  {`${getShortenValue(converted1)}(Aggr)`}
                </span>
              </CopyToClipboard>
              <CopyToClipboard text={converted2}>
                <span data-tooltip-id={`${item.symbol}-${chainId}-2`}>
                  {`${getShortenValue(converted2)}(State)`}
                </span>
              </CopyToClipboard>
            </div>
            <Tooltip
              id={`${item.symbol}-${chainId}`}
              place="top"
              content={converted1}
            />
            <Tooltip
              id={`${item.symbol}-${chainId}-2`}
              place="bottom"
              content={converted2}
            />
          </td>
        );
      })}
      <td className="values">
        <div className="d-clomumn">
          <CopyToClipboard text={getBalanceWithComma(item.sum.toString())}>
            <span> {`${getBalanceWithComma(item.sum.toString())}(Aggr)`} </span>
          </CopyToClipboard>
          <CopyToClipboard
            text={getBalanceWithComma(item.sumInAggr.toString())}
          >
            <span>
              {" "}
              {`${getBalanceWithComma(item.sumInAggr.toString())}(State)`}{" "}
            </span>
          </CopyToClipboard>
        </div>
      </td>

      <td
        className={
          item.sum.eq(item.aggrValue) && item.sumInAggr.eq(item.aggrValue)
            ? "values"
            : "values wrong-value"
        }
      >
        <CopyToClipboard text={getBalanceWithComma(item.aggrValue.toString())}>
          <span>
            {" "}
            {`${getBalanceWithComma(item.aggrValue.toString())}`}{" "}
          </span>
        </CopyToClipboard>
      </td>
    </tr>
  );
};

export default BalanceTbRow;
