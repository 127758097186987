import { initializeConnector } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { ConnectionType } from './types'

export const [metaMask, hooks] = initializeConnector((actions) => new MetaMask({ actions }))

export const injectedConnection = {
    getName: () => "Browser Wallet",
    connector: metaMask,
    hooks,
    type: ConnectionType.INJECTED,
    shouldDisplay: () => true,
}