import { useState, useEffect } from "react";
import { noncePairs } from "config/constants/noncePairs";
import {
  getBoundPacket,
  // getInBoundNonce,
  // getOutBoundNonce,
} from "utils/graphHelper";
import useGetLookupAddress from "./useGetLookupAddress";
import { getPathToDestinationChain } from "../utils/helper";

const useGetNonces = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const calls = noncePairs.map((_item, index) => {
        // console.log(_item.fromChainId, _item.toChainId, remoteLookupAddrs[index])
        return getBoundPacket(_item.fromChainId, _item.toChainId);
      });
      const values = await Promise.all(calls).then((value) => {
        return value;
      });

      const temp = noncePairs.map((pair, index) => {
        const pairIndex = noncePairs.findIndex(
          (_) =>
            _.fromChainId === pair.toChainId && _.toChainId === pair.fromChainId
        );
        const pairValue = values[pairIndex];
        return {
          ...pair,
          inBoundNonce: pairValue.length ? pairValue[0]?.lastInBoundNonce : 0,
          outBoundNonce: values[index].length
            ? values[index][0].lastOutBoundNonce
            : 0,
          status:
            !values[index].length && !pairValue.length
              ? true
              : values[index][0]?.lastInBoundNonce ===
                  pairValue[0]?.lastOutBoundNonce &&
                values[index][0]?.lastOutBoundNonce ===
                  pairValue[0]?.lastInBoundNonce,
          path: values[index].length > 0 ? values[index][0]?.path : getPathToDestinationChain(pair.toChainId)
        };
      });
      if (
        temp.length === noncePairs.length &&
        values.length === noncePairs.length
      ) {
        setData(temp);
      }
    };
    get();
  },[]);

  return data;
};

// const useGetNonces = () => {
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     const get = async () => {
//       const inCalls = [];
//       const outCalls = noncePairs.map((_item) => {
//         inCalls.push(getInBoundNonce(_item.fromChainId, _item.toChainId));
//         return getOutBoundNonce(_item.fromChainId, _item.toChainId);
//       });
//       const outValues = await Promise.all(outCalls).then((value) => {
//         return value;
//       });
//       const inValues = await Promise.all(inCalls).then((value) => {
//         return value;
//       });

//       const temp = noncePairs.map((pair, index) => {
//         const pairIndex = noncePairs.findIndex(
//           (_) =>
//             _.fromChainId === pair.toChainId && _.toChainId === pair.fromChainId
//         );
//         const pairOutValue = outValues[pairIndex];
//         const pairInValue = inValues[pairIndex];
//         return {
//           ...pair,
//           inBoundNonce: inValues[index].length ? inValues[index][0]?.nonce : 0,
//           outBoundNonce: outValues[index].length
//             ? outValues[index][0].nonce
//             : 0,
//           status:
//             !outValues[index].length &&
//             !pairInValue.length &&
//             !pairOutValue.length
//               ? true
//               : outValues[index][0]?.nonce === inValues[index][0]?.nonce,
//         };
//       });
//       if (
//         temp.length === noncePairs.length &&
//         outValues.length === noncePairs.length
//       ) {
//         setData(temp);
//       }
//     };
//     get();
//   }, []);

//   return data;
// };

export default useGetNonces;
