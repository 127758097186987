import React from "react";
import TableRow from "./TableRow";
import { AVAILABLE_NETWORKS, networkNamesByChainId } from "config";
import useGetCollateralBalance from "hooks/useGetCollateralBalance";

const CollateralTable = () => {
  const data = useGetCollateralBalance();
  return (
    <div>
      <div className="aggregator-title" id="totalCollateral">Total Collateral</div>

      {data ? (
        <div className="aggregator-value-wrapper">
          <div className="aggregator-value-container">
            <table className="aggregator-table">
              <thead>
                <tr>
                  <th style={{ width: 110 }}>Collateral</th>
                  {AVAILABLE_NETWORKS.map((chId) => {
                    return (
                      <th
                        key={networkNamesByChainId[chId]}
                      >{`${networkNamesByChainId[chId]}`}</th>
                    );
                  })}
                  <th>Sum</th>
                  <th>Cross Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return <TableRow item={item} key={index} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="d-flex empty-card text">Loading...</div>
      )}
    </div>
  );
};

export default CollateralTable;
