import { useState, useEffect } from "react";
import { AVAILABLE_NETWORKS, lzChainIds, BIG_ZERO } from "config";
import collaterals from "config/constants/collaterals";
import {
  useGetExternStateTokenContract,
  useGetMainChainContracts,
} from "./useContracts";

const useGetSynthBalance = (address) => {
  const [data, setData] = useState(null);
  const externContracts = useGetExternStateTokenContract();
  const { aggrContract } = useGetMainChainContracts();

  useEffect(() => {
    const get = () => {
      const temp = [];
      collaterals.forEach(async (_) => {
        setData(null);
        const calls = AVAILABLE_NETWORKS.map((chainId) => {
          return externContracts[chainId].collateralByIssuer(
            _.currencyKey,
            address
          );
        });
        const calls2 = AVAILABLE_NETWORKS.map((chainId) => {
          return aggrContract.collateralByIssuerAggregation(
            _.currencyKey,
            lzChainIds[chainId],
            address
          );
        });

        const balances = await Promise.all(calls).then((value) => {
          return value;
        });
        const balances2 = await Promise.all(calls2).then((value) => {
          return value;
        });

        const sum = balances.reduce((a, b) => a.add(b), BIG_ZERO);
        const sumInAggr = balances2.reduce((a, b) => a.add(b), BIG_ZERO);

        const aggrValue = await aggrContract.collateralByIssuer(
          _.currencyKey,
          address
        );

        const balance = {};
        const balanceInAggr = {};

        AVAILABLE_NETWORKS.forEach((chainId, index) => {
          balance[chainId] = balances[index];
          balanceInAggr[chainId] = balances2[index];
        });

        temp.push({
          ..._,
          balance,
          balanceInAggr,
          sum,
          sumInAggr,
          aggrValue,
        });
        if (temp.length === collaterals.length) {
          setData(temp);
        }
      });
    };
    if (address.length === 42 && externContracts && aggrContract) {
      get();
    }
  }, [address, externContracts, aggrContract]);

  return data;
};

export default useGetSynthBalance;
