import React from 'react';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useAuth from 'hooks/useAuth';
import { shortenAddress } from 'utils/formatString';
import { ConnectionType } from 'connectors/types';

const ConnectWalletButton = () => {
    const { account } = useActiveWeb3React();
    const { login, logout } = useAuth();
    const handleClick = async () => {
        if (account) {
            logout();
            return;
        }
        login(ConnectionType.INJECTED);
    }
    return (
        <button className='wallet-connect-btn' onClick={handleClick}>{account ? `${shortenAddress(account)}` : `CONNECT METAMASK`}</button>
    )
}

export default ConnectWalletButton;
