import { useState, useEffect } from "react";
import { AVAILABLE_NETWORKS, mainChainId } from "config";
import collaterals from "config/constants/collaterals";
import { getCollateralValue, getCollateralTotalValue } from "utils/graphHelper";
import { getDecimalAmount, BIG_ZERO } from "utils/number";
import BigNumber from "bignumber.js";

const useGetCollateralBalance = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const get = () => {
      const temp = [];
      collaterals.forEach(async (_) => {
        setData(null);
        const calls = AVAILABLE_NETWORKS.map((chainId) => {
          return getCollateralValue(chainId, _.symbol);
        });

        const balances2 = await getCollateralTotalValue(mainChainId, _.symbol);
        const balances = await Promise.all(calls).then((value) => {
          return value;
        });
        const convertedBalance = balances.map((subArr) => {
          return subArr.length ? getDecimalAmount(subArr[0].tvl, _.decimals) : BIG_ZERO
        })
        const convertedTotalBalance = balances2.length ? getDecimalAmount(balances2[0].tvl, _.decimals) : BIG_ZERO

        const sum = BigNumber.sum.apply(BIG_ZERO, convertedBalance)

        const balance = {};

        AVAILABLE_NETWORKS.forEach((chainId, index) => {
          balance[chainId] = convertedBalance[index];
        });

        temp.push({
          ..._,
          balance,
          sum,
          aggrValue: convertedTotalBalance
        });
        if (temp.length === collaterals.length) {
          setData(temp);
        }
      });
    };
    get();
  }, []);

  return data;
};

export default useGetCollateralBalance;
