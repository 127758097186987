import CopyToClipboard from "react-copy-to-clipboard";
import { networkNamesByChainId } from "config";
import { getNetworkIcon } from "utils";
import { shortenTxHash } from "utils/formatString";
import CopyIcon from "assets/copy.svg";

const NonceTableRowForUser = ({ item }) => {
  
  return (
    <tr>
      <td className="total-supply-name">
        <div className="pair-image">
          <img
            src={getNetworkIcon(networkNamesByChainId[item.fromChainId])}
            alt=""
            className="token-icon"
          />
          <img
            src={getNetworkIcon(networkNamesByChainId[item.toChainId])}
            alt=""
            className="token-icon2"
          />
        </div>
        <div className="pair-name">
          {" "}
          {`${networkNamesByChainId[item.fromChainId]}/${
            networkNamesByChainId[item.toChainId]
          }`}{" "}
        </div>
      </td>
      <td className="values">
        <span> {item.outBoundNonce.toString()} </span>
      </td>
      <td className="values">
        <span> {item.inBoundNonce.toString()} </span>
      </td>
      <td
        className={
          item.status ? "values" : "values wrong-value"
        }
      >
        <span> {item.status ? `Success` : "Failed"} </span>
      </td>
      <td className="values">
        
      <div className="d-flex">
          {item.lastOutBoundTxn && <CopyToClipboard text={item.lastOutBoundTxn ?? ''}>
            <div className="svg-container">
              <img src={CopyIcon} alt="copy" />
            </div>
          </CopyToClipboard>}
          <span> {shortenTxHash(item.lastOutBoundTxn ?? '')} </span>
        </div>
      </td>
    </tr>
  );
};

export default NonceTableRowForUser;
