import { BIG_ZERO } from "..";

const collaterals = [
    {
        symbol: 'ETH',
        currencyKey: '0x4554480000000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        decimals: 18,
        aggrValue: BIG_ZERO,
        address: {
            421614: '0x08262eDab126645C8A0D78c4B88867098e7d8a0b'
        }
    },
    {
        symbol: 'USDT',
        currencyKey: '0x5553445400000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        decimals: 18,
        aggrValue: BIG_ZERO,
        address: {
            421614: '0x10779217aED3C5282b5263Fd175aA8d1c0135a84'
        }
    },
    {
        symbol: 'USDC',
        currencyKey: '0x5553444300000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        decimals: 18,
        aggrValue: BIG_ZERO,
        address: {
            421614: '0x1dA2bD8edc883c1965ee228371F97bbC5d210c18'
        }
    },
    {
        symbol: 'WSTETH',
        currencyKey: '0x5753544554480000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        decimals: 18,
        aggrValue: BIG_ZERO,
        address: {
            421614: '0xD8Bba9C67492e76aE0332bd4c21e9c7FFEA0dB7f'
        }
    },
    {
        symbol: 'RSETH',
        currencyKey: '0x5253455448000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        decimals: 18,
        aggrValue: BIG_ZERO,
        address: {
            421614: '0xE683b97cbD545376A79F4fe7FcfFE71451B0f8A0'
        }
    },
    {
        symbol: 'EETH',
        currencyKey: '0x4545544800000000000000000000000000000000000000000000000000000000',
        totalSupply: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        sum: BIG_ZERO,
        balance: {
            11155111: BIG_ZERO,
            43113: BIG_ZERO,
            421614: BIG_ZERO,
            97: BIG_ZERO,
            80002: BIG_ZERO,
            599: BIG_ZERO
        },
        decimals: 18,
        aggrValue: BIG_ZERO,
        address: {
            421614: '0xC1f2fCc3673D9960Da523Bf743FE5ba589e7395c'
        }
    }
];

export default collaterals;
