import { useState, useEffect } from "react";
import { noncePairs } from "config/constants/noncePairs";
import { getUserNonceFromBridge } from "utils/graphHelper";

const useGetNoncesByUser = (address) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const calls = noncePairs.map((_item, index) => {
        return getUserNonceFromBridge(
          _item.fromChainId,
          _item.toChainId,
          address.toLowerCase()
        );
      });
      const values = await Promise.all(calls).then((value) => {
        return value;
      });

      const temp = noncePairs.map((pair, index) => {
        const pairIndex = noncePairs.findIndex(
          (_) =>
            _.fromChainId === pair.toChainId && _.toChainId === pair.fromChainId
        );
        const pairValue = values[pairIndex];
        return {
          ...pair,
          inBoundNonce: pairValue.length
            ? pairValue[0]?.inBoundNonce
            : 0,
          lastOutBoundTxn: values[index].length
            ? values[index][0].lastOutBoundTxn
            : "",
          outBoundNonce: values[index].length
            ? values[index][0].outBoundNonce
            : 0,
          status:
            !values[index].length && !pairValue.length
              ? true
              : values[index][0]?.inBoundNonce === pairValue[0]?.outBoundNonce &&
                values[index][0]?.outBoundNonce === pairValue[0]?.inBoundNonce,
        };
      });
      if (
        temp.length === noncePairs.length &&
        values.length === noncePairs.length
      ) {
        setData(temp);
      }
    };
    if (address) {
      get();
    }
  }, [address]);

  return data;
};

export default useGetNoncesByUser;
